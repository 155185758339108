/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Filters
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-filters {}

  .c-filters__label {
    @include typo-headline;
  }

  .c-filters__label--center {
    text-align: center;
  }

  .c-filters__list {
    display: flex;
    padding: 0;
    margin: 0;
    overflow: auto;
    list-style: none;

    .c-filters__label + & {
      margin-top: rem(20px);
    }
  }

    .c-filters__item {
      flex: 0 0 auto;

      & + & {
        margin-left: rem(10px);
      }
    }

      .c-filters__link {
        --label-background: transparent;
        --label-color: currentColor;
        --label-border: currentColor;

        text-decoration: none;
        @include transition(background);

        &:hover,
        &:focus {
          --label-background: #{color("neutral-100")};
        }
      }

      .c-filters__link--active {
        &:not(:hover),
        &:not(:focus) {
          --label-background: #{color("black")};
          --label-color: #{color("white")};
          --label-border: #{color("black")};
        }
      }

/*******************************************************************************
 * Print styles
*******************************************************************************/

@media only print {
  .c-filters__list {
    flex-wrap: wrap;
  }

    .c-filters__item {
      margin: rem(5px);
    }
}
