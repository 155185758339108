/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Heading
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-heading {
  padding-top: calc(var(--header-height) + var(--heading-pt));
  padding-bottom: var(--heading-pb);
  margin-top: calc(var(--header-height) * -1);
  text-align: center;
  color: color("white");
  background: color("black");
  border-radius: var(--doc-frame-radius);

  @include mq($until: "medium") {
    --heading-pt: 1rem;
    --heading-pb: #{rem(40px)};
  }

  @include mq($from: "medium") {
    --heading-pt: #{rem(30px)};
  }

  @include mq($from: "medium", $until: "large") {
    --heading-pb: #{rem(60px)};
  }

  @include mq($from: "large") {
    --heading-pb: #{rem(80px)};
  }
}

.c-heading--pictured {
  color: color("foreground");
  background-position: center;
  background-size: cover;
}

  .c-heading__wrapper {
    --wrapper-width: #{rem(780px)};

    > * {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

    .c-heading__breadcrumb {
      @include mq($until: "medium") {
        margin-bottom: rem(30px);
      }

      @include mq($from: "medium") {
        margin-bottom: rem(40px);
      }
    }

    .c-heading__label {
      --label-background: #{color("white")};
      --label-color: #{color("secondary")};
    }

    .c-heading__title,
    .c-heading__caption {
      max-width: rem(780px);
      margin-right: auto;
      margin-left: auto;
    }

    .c-heading__title {
      margin-top: rem(30px);
    }

    .c-heading__caption {
      margin-top: rem(10px);

      @include mq($until: "medium") {
        font-size: rem(14px);
      }
    }

    .c-heading__meta {
      margin-top: rem(10px);
    }

/*******************************************************************************
 * Print styles
*******************************************************************************/

@media only print {
  .c-heading {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 20pt;
  }
}
