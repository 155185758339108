/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Typography
 * Type: Tool
 * Description: Text common styles.
**/

/*******************************************************************************
 * Mixins
*******************************************************************************/

@mixin typo-title() {
  font-family: $font-serif;
  font-size: var(--font-size, 1rem);
  font-weight: $FONT_BOLD;
  line-height: 1.33;
}

/**
 * Title level 1
*******************************************************************************/

@mixin typo-h1() {
  @include typo-title;

  @include mq($until: "medium") {
    --font-size: #{rem(30px)};
  }

  @include mq($from: "medium", $until: "large") {
    --font-size: #{rem(42px)};
  }

  @include mq($from: "large") {
    --font-size: #{rem(56px)};
  }
}

/**
 * Title level 2
*******************************************************************************/

@mixin typo-h2() {
  @include typo-title;

  @include mq($until: "medium") {
    --font-size: #{rem(26px)};
  }

  @include mq($from: "medium", $until: "large") {
    --font-size: #{rem(38px)};
  }

  @include mq($from: "large") {
    --font-size: #{rem(46px)};
  }
}

/**
 * Title level 3
*******************************************************************************/

@mixin typo-h3() {
  @include typo-title;

  @include mq($until: "medium") {
    --font-size: #{rem(24px)};
  }

  @include mq($from: "medium", $until: "large") {
    --font-size: #{rem(30px)};
  }

  @include mq($from: "large") {
    --font-size: #{rem(36px)};
  }
}

/**
 * Title level 4
*******************************************************************************/

@mixin typo-h4() {
  @include typo-title;

  @include mq($until: "medium") {
    --font-size: #{rem(22px)};
  }

  @include mq($from: "medium", $until: "large") {
    --font-size: #{rem(24px)};
  }

  @include mq($from: "large") {
    --font-size: #{rem(26px)};
  }
}

/**
 * Title level 5
*******************************************************************************/

@mixin typo-h5() {
  @include typo-title;

  @include mq($until: "medium") {
    --font-size: #{rem(18px)};
  }

  @include mq($from: "medium") {
    --font-size: #{rem(20px)};
  }
}

/**
 * Headline
*******************************************************************************/

@mixin typo-headline() {
  font-family: $font-default;
  font-size: rem(14px);
  font-weight: $FONT_BOLD;
  line-height: 1.21;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

/**
 * Large
*******************************************************************************/

@mixin typo-large() {
  font-size: rem(24px);
}

/**
 * Medium
*******************************************************************************/

@mixin typo-medium() {
  font-size: rem(20px);
}

/**
 * Normal
*******************************************************************************/

@mixin typo-normal() {
  font-size: rem($global-font-size);
}

/**
 * Small
*******************************************************************************/

@mixin typo-small() {
  font-size: rem(14px);
}
