/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Search
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-search {}

  .c-search__form {
    max-width: rem(590px);
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    > * + * {
      margin-top: var(--search-form-child-spacing, #{rem(20px)});
    }
  }

    .c-search__noresult,
    .c-search__count {
       @include mq($until: "medium") {
        --search-form-child-spacing: #{60px};
      }

      @include mq($from: "medium") {
        --search-form-child-spacing: #{60px};
      }
    }

    .c-search__noresult {
      @include typo-h4;
      color: color("secondary");
    }

    .c-search__count {
      --label-color: currentColor;
      --label-background: transparent;
      --label-border: currentColor;
    }

  .c-search__results {
    width: 100%;
    max-width: rem(790px);
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
    list-style: none;

    @include mq($until: "medium") {
      margin-top: rem(60px);
    }

    @include mq($from: "medium") {
      margin-top: rem(60px);
    }
  }

    .c-search__result {
      position: relative;

      @include mq($from: "medium") {
        display: flex;
      }
    }

    .c-search__result + .c-search__result {
      @include mq($until: "medium") {
        margin-top: rem(20px);
      }

      @include mq($from: "medium") {
        margin-top: rem(40px);
      }
    }

      .c-search__result-picture {
        overflow: hidden;
        border-radius: rem(20px);

        @include mq($until: "medium") {
          max-width: rem(160px);
          margin-bottom: rem(10px);
        }

        @include mq($from: "medium") {
          flex: 0 0 auto;
          max-width: rem(280px);
          margin-right: rem(24px);
        }
      }

      .c-search__result-infos {}

        .c-search__result-tag {
          margin-bottom: rem(10px);
        }

        .c-search__result-title {
          @include typo-h5;

          margin-top: 0;
          margin-bottom: 0;
          color: color("secondary");
        }

          .c-search__result-link {
            text-decoration: none;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
            }

            &:hover,
            &.focus-visible {
              text-decoration: underline;
            }
          }

        .c-search__result-excerpt {
          margin-top: rem(10px);
          margin-bottom: 0;
        }
