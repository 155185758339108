/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Option
 * Type: Component
 * Description: A single option respresented as a checkbox or radio button.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

$option-size: rem(18px);
$option-gap: rem(8px);
$option-accent-color: color("secondary");
$option-accent-contrast-color: color("white");
$option-border-size: rem(2px);
$option-border-color: color("neutral-400");
$option-disabled-background-color: color("neutral-400");
$option-disabled-label-color: colo("neutral-500");
$option-focus-outline-size: rem(2px);
$option-top-alignment: rem(2px);
$option-radio-checked-offset: rem(3px);

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-form-option {
  --option-border-color: #{$option-border-color};
  --option-background-color: transparent;
  --option-color: inherit;
  --option-label-color: inherit;

  display: flex;
}

  .c-form-option__control {
    position: relative;
    z-index: 1;
    flex: 0 0 auto;
  }

    .c-form-option__input,
    .c-form-option__doppel {
      display: block;
      width: $option-size;
      height: $option-size;
      margin-top: $option-top-alignment;
    }

    .c-form-option__input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0;
    }

    .c-form-option__doppel {
      position: relative;
      color: var(--option-color);
      background: var(--option-background-color);
      border: $option-border-size solid var(--option-border-color);
      @include transition(background box-shadow color);

      .c-form-option__input:focus ~ & {
        box-shadow:
          0 0 0 $option-focus-outline-size $option-accent-contrast-color,
          0 0 0 ($option-focus-outline-size * 2) $option-accent-color;
      }

      .c-form-option__input:checked ~ & {
        --option-border-color: #{$option-accent-color};
        --option-background-color: #{$option-accent-color};
      }
    }

      .c-form-option__icon {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        @include transition(transform);

        .c-form-option__input:not(:checked) ~ .c-form-option__doppel & {
          transform: scale(0);
        }
      }

  .c-form-option__details {
    flex: 1 1 auto;
    margin-left: $option-gap;
    font-size: rem(14px);
  }

    .c-form-option__label {
      display: block;
      font-weight: $FONT_REGULAR;
      color: var(--option-label-color);
    }

/**
 * Checkbox
 ******************************************************************************/

.c-form-option--checkbox {
  .c-form-option__doppel {
    border-radius: rem(2px);
  }

  .c-form-option__input:checked ~ .c-form-option__doppel {
    --option-color: #{$option-accent-contrast-color};
  }
}

/**
 * Radio
 ******************************************************************************/

.c-form-option--radio {
  .c-form-option__doppel {
    border-radius: 50%;

    &::before {
      content: "";
      position: absolute;
      top: $option-radio-checked-offset;
      right: $option-radio-checked-offset;
      bottom: $option-radio-checked-offset;
      left: $option-radio-checked-offset;
      background: $option-accent-contrast-color;
      border-radius: 50%;
      transform: scale(0);
      @include transition(transform);
    }
  }

  .c-form-option__input:checked ~ .c-form-option__doppel {
    &::before {
      transform: scale(1);
    }
  }
}

/*******************************************************************************
 * Feedback styles
*******************************************************************************/

.c-form-option--disabled {
  --option-background-color: #{color("neutral-50")};
  --option-label-color: #{$option-disabled-label-color};

  .c-form-option__input,
  .c-form-option__doppel {
    cursor: not-allowed;
  }
}

.c-form-option--error {
  --option-background-color: #{color("error-50")};

  .c-form-option__input:not(:checked) ~ .c-form-option__doppel {
    --option-border-color: #{color("error-500")};
  }
}
