/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Focus
 * Type: Generic
 * Description: Define common :focus-visible styles, using a JS polyfill (https://github.com/WICG/focus-visible).
**/

/*******************************************************************************
 * Styles
*******************************************************************************/

/* Remove outline for non-keyboard :focus */
.js-focus-visible :focus:not(.focus-visible) {
  outline: 0;
}

/* Optional: Customize .focus-visible */
.focus-visible {
  outline: rem(2px) solid color("outline");
  outline-offset: rem(2px);
}
