/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Number
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-download {
  padding: rem(20px);
  border: rem(1px) solid color("primary");
  border-radius: rem(30px);

  @include mq($from: "medium") {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

  .c-download__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(40px);
    height: rem(40px);
    font-size: rem(20px);
    color: color("white");
    background: color("tertiary");
    border-radius: rem(10px);
  }

  .c-download__infos {
    margin-top: 0;
    margin-bottom: 0;

    @include mq($until: "medium") {
      margin-top: rem(20px);
    }

    @include mq($from: "medium") {
      display: flex;
      flex: 1 1 auto;
      align-items: baseline;
      margin-right: rem(30px);
      margin-left: rem(30px);
    }
  }

    .c-download__title {
      @include typo-h5;
      color: color("secondary");
    }

    .c-download__meta {
      font-size: rem(14px);
      font-weight: $FONT_BOLD;
      letter-spacing: 0.05em;
      color: color("neutral-500");

      @include mq($until: "medium") {
        display: block;
        margin-top: rem(10px);
      }

      @include mq($from: "medium") {
        margin-left: rem(20px);
      }
    }

  .c-download__button {
    @include mq($until: "medium") {
      margin-top: rem(10px);
    }
  }

/*******************************************************************************
 * Print styles
*******************************************************************************/

@media only print {
  .c-download {
    break-inside: avoid;
  }
}
