/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Page
 * Type: Elements
 * Description: Simple page-level setup.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:color";
@use "sass:math";

/*******************************************************************************
 * Default styles
*******************************************************************************/

:root {
  --doc-frame-background: #{color("background-alt")};
  --doc-frame-background-rgb: #{color.red(color("background-alt")), color.green(color("background-alt")), color.blue(color("background-alt"))};
  --doc-frame-gap: #{rem(5px)};
  --doc-frame-radius: #{rem(10px)};

  @include mq($from: "medium") {
    --doc-frame-gap: #{rem(10px)};
  }

  @include mq($from: "large") {
    --doc-frame-radius: #{rem(20px)};
  }
}

/**
 * Allow percentage-based heights in the application
**/
html,
body {
  height: 100%;
}

/**
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
**/
html {
  overflow-y: scroll; /* [2] */
  font-family: $font-default;
  font-size: math.div($global-font-size, 16px) * 1em; /* [1] */
  line-height: $global-line-height; /* [1] */
  color: color("foreground");
  background: color("background") none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  scroll-padding-top: rem(100px);
}

body {
  &.white-sheet {
    --doc-frame-background: #{color("background")};
    --doc-frame-background-rgb: #{color.red(color("background")), color.green(color("background")), color.blue(color("background"))};
  }

  &.no-scroll {
    overflow-y: hidden;
  }
}

.frame {
  margin: var(--doc-frame-gap);
  background: var(--doc-frame-background);

  @include mq($from: 1500px) {
    --doc-frame-rule-z: 2;

    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: var(--header-height);
      bottom: 0;
      left: calc(50% - var(--wrapper-width) * 0.5 - #{rem(120px)});
      z-index: var(--doc-frame-rule-z);
      width: rem(1px);
      background: rgba(color("secondary"), 0.2);

      .nav-is-open & {
        top: 0;
        z-index: $global-z-header + 1;
        background: rgba(color("white"), 0.2);
      }
    }
  }
}

/*******************************************************************************
 * Print styles
*******************************************************************************/

@media only print {
  @page {
    margin: 1cm 1.5cm;
  }

  html,
  body {
    height: auto;
  }

  html {
    font-size: 90%;
  }

  *,
  *::before,
  *::after {
    color: #000 !important;
    background: transparent none !important;
    border-color: #000 !important;
    box-shadow: none !important;
  }

  .frame {
    margin: 0;
  }
}
