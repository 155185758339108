/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Meta
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-meta {
  display: inline-flex;
  align-items: center;
}

  .c-meta__icon {
    --meta-icon-size: #{rem(30px)};
    --meta-icon-gap: #{rem(20px)};

    margin-right: var(--meta-icon-gap);
    font-size: var(--meta-icon-size);

    .c-meta--compact & {
      --meta-icon-size: #{rem(24px)};
      --meta-icon-gap: #{rem(10px)};
    }
  }

  .c-meta__text {
    .c-meta--compact & {
      font-size: rem(14px);
      font-weight: $FONT_BOLD;
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }
  }
