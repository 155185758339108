/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Home media
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-home-media {
  @include mq($from: "large") {
    display: grid;
    grid-template-columns:
      1fr
      min(calc(0.4875 * (100% - 2 * var(--wrapper-gutter))), calc(0.4875 * var(--wrapper-width)))
      min(calc(0.1083 * (100% - 2 * var(--wrapper-gutter))), calc(0.1083 * var(--wrapper-width)))
      min(calc(0.4042 * (100% - 2 * var(--wrapper-gutter))), calc(0.4042 * var(--wrapper-width)))
      1fr;
  }
}

  .c-home-media__content {
    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    > * + * {
      margin-top: rem(20px);
    }

    @include mq($until: "large") {
      max-width: calc(var(--wrapper-width) + 2 * var(--wrapper-gutter));
      padding-right: var(--wrapper-gutter);
      padding-left: var(--wrapper-gutter);
      margin-right: auto;
      margin-left: auto;
    }

    @include mq($from: "large") {
      align-self: center;
      grid-column: 2 / 3;
    }
  }

    .c-home-media__label {}

    .c-home-media__title {
      @include typo-h2;
      color: color("secondary");
    }

    .c-home-media__text {}

    .c-home-media__action {}

  .c-home-media__picture {
    @include mq($until: "large") {
      margin-top: rem(40px);
      margin-right: calc(var(--doc-frame-gap) * -1);
      margin-left: calc(var(--doc-frame-gap) * -1);
    }

    @include mq($from: "large") {
      max-width: rem(680px);
      margin-right: calc(var(--doc-frame-gap) * -1);
      grid-column: 4 / 6;
    }
  }
