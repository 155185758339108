/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Accordion
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-accordion {}

  .c-accordion__head {
    position: relative;
    display: block;
    cursor: pointer;
    color: color("primary");

    // Use separate ::marker and ::-webkit-details-marker rules to prevent one to invalid the other.
    &::marker {
      display: none;
      color: transparent;
    }

    // Use separate ::marker and ::-webkit-details-marker rules to prevent one to invalid the other.
    &::-webkit-details-marker {
      display: none;
      color: transparent;
    }

    &::before {
      content: "" !important; // Override default IE/Edge declaration
    }
  }

    .c-accordion__title {
      @include typo-h5;
      flex: 1 1 auto;
      padding-right: rem(48px);
      margin-top: 0;
      margin-bottom: 0;
    }

    .c-accordion__marker {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: rem(-12px);
      font-size: rem(24px);
      @include transition(transform);

      .c-accordion[open] & {
        transform: rotate(-180deg);
      }
    }

  .c-accordion__body {
    --richtext-mxwidth: 100%;

    margin-top: rem(16px);
  }
