/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Wrapper
 * Type: Object
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

:root {
  --wrapper-default-width: #{rem(1200px)};
  --wrapper-width: var(--wrapper-default-width);
  --wrapper-gutter: #{rem(20px)};

  @include mq($from: "medium") {
    --wrapper-gutter: #{rem(25px)};
  }
}

.o-wrapper {
  max-width: calc(var(--wrapper-width) + 2 * var(--wrapper-gutter));
  padding-right: var(--wrapper-gutter);
  padding-left: var(--wrapper-gutter);
  margin-right: auto;
  margin-left: auto;
}

.o-wrapper--full {
  --wrapper-width: 100%;
}
