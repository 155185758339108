/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Accordion list
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-accordionlist {
  padding: 0;
  margin: 0;
  list-style: none;
}

  .c-accordionlist__item {
    padding-top: rem(30px);
    padding-bottom: rem(30px);
    border-bottom: rem(1px) solid color("secondary");
  }
