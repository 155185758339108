/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Logo
 * Type: Component
**/

@use "sass:math";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-logo {
  --logo-size: #{rem(180px)};

  display: block;
  width: var(--logo-size);
  height: calc(math.div(65, 180) * var(--logo-size));
}

  .c-logo__circle,
  .c-logo__text {
    fill: var(--logo-primary-color, currentColor);
  }

  .c-logo__cross {
    fill: var(--logo-accent-color, #{color("orange")});
  }
