/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Number
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-number {}

  .c-number__label {
    margin-bottom: rem(10px);
  }

  .c-number__text {
    display: flex;
    flex-direction: column;
  }

    .c-number__number {
      @include typo-h2;
    }

    .c-number__desc {
      @include typo-h5;
      margin-top: rem(10px);
      color: color("primary");
    }

/*******************************************************************************
 * Print styles
*******************************************************************************/

@media only print {
  .c-number {
    break-inside: avoid;
  }
}
