/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Label
 * Type: Component
 * Description:
 *  A label to paired with an input control to indicates what we want from the
 *  user.
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-form-label {
  font-size: rem(14px);
  font-weight: $FONT_BOLD;
}

  .c-form-label__indic {
    // Often corresponding to required fields: <abbr title="...">*</abbr>
    &[title] {
      text-decoration: none;
    }

    &:not(abbr) {
      font-style: italic;
      font-weight: $FONT_REGULAR;
      color: color("neutral-500");
    }
  }
