/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Icon
 * Type: Component
 * Description: Displaying a SVG square icon.
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-icon {
  display: inline-block;
  line-height: 0.8em;

  svg {
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: -0.2em; // https://fvsch.com/code/svg-icons/how-to/#section-styling
    fill: currentColor;
  }
}
