/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Global
 * Type: Setting
 * Description:
 *   The global settings file contains any project-wide variables; things that
 *   need to be made available to the entire codebase.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:map";
@use "sass:math";

/*******************************************************************************
 * Typography
*******************************************************************************/

$global-font-size: 16px;
$global-line-height-px: 25px;
$global-line-height: math.div($global-line-height-px, $global-font-size);

/*******************************************************************************
 * Breakpoints
*******************************************************************************/

//  App's core breakpoints, used for utilities or generic loops.
$global-core-breakpoints: (
  "small": 320px,
  "xsmall": 480px,
  "medium": 720px,
  "large": 960px,
  "xlarge": 1440px,
);

/**
 * Store current core and future breakpoints for sass-mq use.
 * So this map will grow through the app step by step as we storing new
 * breakpoints (because low usage or component's specifics).
 */
$global-mq-breakpoints: map.merge(
  $global-core-breakpoints,
  (
  ),
);

/*******************************************************************************
 * Z-indexes
*******************************************************************************/

$global-z-header: 20;
$global-z-footer: 10;

/*******************************************************************************
 * UI
*******************************************************************************/

$global-spacing-unit: 10px;
// $global-border-width: 2px;
// $global-border-color: $color-black;
// $global-gutter: 20px;
