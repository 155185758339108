/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Input
 * Type: Component
 * Description: A simple text input.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

$input-height: rem(40px);
$input-padding-y: rem(8px);
$input-padding-x: rem(12px);
$input-background: transparent;
$input-border-size: rem(1px);
$input-border-color: color("neutral-400");
$input-border-radius: rem(4px);
$input-placeholder-color: color("neutral-500");
$input-outline-size: rem(3px);
$input-icon-size: rem(18px);

$input-action-enable: false;
$input-action-size: $input-icon-size;

$input-select-icon: "<svg viewBox=\"0 0 18 18\" xmlns=\"http://www.w3.org/2000/svg\">\
  <path d=\"M5.33582 6.22119L8.77832 9.65619L12.2208 6.22119L13.2783 7.27869L8.77832 11.7787L4.27832 7.27869L5.33582 6.22119Z\" fill=\"%color%\" />\
</svg>";

@mixin input-outline {
  box-shadow: 0 0 0 $input-outline-size var(--input-outline-color);
}

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-form-input {
  --input-height: #{$input-height};
  --input-background: #{$input-background};
  --input-border-color: #{$input-border-color};

  position: relative;
}

  .c-form-input__control {
    width: 100%;
    height: var(--input-height);
    padding: ($input-padding-y - $input-border-size) ($input-padding-x - $input-border-size);
    font: inherit;
    appearance: none;
    color: currentColor;
    background: var(--input-background);
    border: $input-border-size solid;
    border-color: var(--input-border-color);
    border-radius: $input-border-radius;
    @include transition(background border box-shadow);

    &::placeholder {
      color: $input-placeholder-color;
    }

    &[disabled] {
      cursor: not-allowed;
      background-color: color("neutral-400");
    }

    &:focus {
      --input-border-color: transparent;
      --input-outline-color: #{color("secondary")};
      outline: 0;

      @include input-outline;
    }
  }

/**
 * Textarea
 ******************************************************************************/

.c-form-input--textarea {
  --input-height: #{rem(120px)};
}

/**
 * Select
 ******************************************************************************/

.c-form-input--select {
  .c-form-input__control {
    padding-right: $input-padding-x * 2 + $input-icon-size;
    background-image: icon($input-select-icon, color("foreground"));
    background-repeat: no-repeat;
    background-position: right $input-padding-x top 50%;
    background-size: $input-icon-size $input-icon-size;
  }
}

/*******************************************************************************
 * With action
*******************************************************************************/

@if $input-action-enable {
  .c-form-input--with-action {
    .c-form-input__control {
      padding-right: $input-padding-x * 2 + $input-action-size;
    }
  }

    .c-form-input__action {
      position: absolute;
      top: $input-padding-y + ($input-height - $input-action-size - (2 * $input-padding-y)) * 0.5;
      right: $input-padding-x;
      display: flex;
      align-items: center;
      justify-content: center;
      width: $input-action-size;
      height: $input-action-size;
      padding: 0;
      font-size: $input-action-size;
      cursor: pointer;
      background: transparent;
      border: 0;
    }
}

/*******************************************************************************
 * Feedback styles
*******************************************************************************/

.c-form-input--error {
  --input-border-color: transparent;

  .c-form-input__control {
    &:not(:focus) {
      --input-background: #{color("error-50")};
      --input-outline-color: #{color("error-500")};
      @include input-outline;
    }
  }
}
