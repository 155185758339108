/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Button
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:color";

$button-inner-space: 8px;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-button {
  --button-medium-icon-only-padding: #{rem(8px 9px)};
  --button-big-icon-only-padding: #{rem(15px)};

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--button-padding, 0);
  font-size: rem(14px);
  font-weight: $FONT_BOLD;
  text-align: var(--button-text-align, center);
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  color: var(--button-color, currentColor);
  background: var(--button-bg, transparent);
  border: rem(1px) solid var(--button-border-color, var(--button-bg));
  border-radius: rem(4px);
  @include transition(background border color);
}

  .c-button__inner {
    display: flex;
    align-items: center;
    margin: rem(-$button-inner-space * 0.5);
  }

    .c-button__icon,
    .c-button__text {
      margin: rem($button-inner-space * 0.5);
    }

    .c-button__icon {
      flex: 0 0 auto;
      font-size: var(--button-icon-size, #{rem(16px)});
      color: var(--button-icon-color, var(--button-color));
      @include transition(color);

      .c-button--revert & {
        order: 2;
      }

      .c-button--medium & {
        font-size: rem(16px);
      }

      .c-button--big & {
        font-size: rem(18px);
      }
    }

    .c-button__text {
      display: var(--button-text-display);

      .c-button--icon & {
        @include hidden-visually;
      }
    }

/*******************************************************************************
 * Variants
*******************************************************************************/

.c-button--medium {
  --button-padding: #{rem(8px 15px)};

  &.c-button--icon {
    --button-padding: #{rem(8px)};
  }
}

.c-button--big {
  --button-padding: #{rem(12px 23px)};

  &.c-button--icon {
    --button-padding: #{rem(14px)};
    --button-icon-size: #{rem(18px)};
  }

  @include mq($until: "medium") {
    --button-padding: #{rem(8px 15px)};

    &.c-button--icon {
      --button-padding: #{rem(8px)};
      --button-icon-size: #{rem(16px)};
    }
  }
}

.c-button--primary {
  &.c-button--light {
    --button-bg: #{color("primary")};
    --button-color: #{color("white")};

    &:hover,
    &.focus-visible {
      --button-bg: #{color("white")};
      --button-color: #{color("primary")};
      --button-border-color: #{color("primary")};
    }
  }

  &.c-button--dark {
    --button-bg: #{color("white")};
    --button-color: #{color("secondary")};

    &:hover,
    &.focus-visible {
      --button-bg: transparent;
      --button-color: #{color("white")};
      --button-border-color: #{color("white")};
    }
  }
}

.c-button--secondary {
  &.c-button--light {
    // --button-bg: #{color("white")};
    --button-color: #{color("primary")};
    --button-border-color: #{color("primary")};

    &:hover,
    &.focus-visible {
      --button-color: #{color("secondary")};
      --button-border-color: #{color("secondary")};
    }
  }

  &.c-button--dark {
    // --button-bg: #{color("primary")};
    --button-color: #{color("white")};
    --button-border-color: #{color("white")};

    &:hover,
    &.focus-visible {
      --button-bg: #{color("white")};
      --button-color: #{color("secondary")};
      --button-border-color: #{color("white")};
    }
  }
}

.c-button--link {
  --button-padding: 0;
  --button-icon-color: #{color("primary")};

  &:hover,
  &.focus-visible {
    --button-icon-color: #{color("orange")};
  }

  &.c-button--dark {
    --button-color: #{color("white")};
    --button-icon-color: initial;
  }
}

/*******************************************************************************
 * Disabled
*******************************************************************************/

.c-button[disabled],
.c-button.is-disabled {
  cursor: not-allowed;

  &,
  &.c-button--primary,
  &.c-button--secondary {
    --button-bg: #{color("neutral-300")};
    --button-color: #{color("neutral-500")};
    --button-border-color: #{color("neutral-300")};
  }

  &.c-button--link {
    --button-bg: initial;
    --button-color: #{color("neutral-400")};
    --button-border-color: initial;
    --button-icon-color: currentColor;
  }
}
