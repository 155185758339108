/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Card2
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@mixin card2-hover-effect($root: false) {
  @if $root == true {
    &:focus-within {
      @content;
    }

    @media (hover: hover) {
      &:hover {
        @content;
      }
    }
  } @else {
    .c-card2--interactive:focus-within & {
      @content;
    }

    @media (hover: hover) {
      .c-card2--interactive:hover & {
        @content;
      }
    }
  }
}

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-card2 {
  position: relative;
  overflow: hidden;
  border: rem(1px) solid color("primary");
  border-radius: rem(20px);
}

.c-card2--interactive {
  will-change: color, transform;
  @include transition(color transform);

  @include card2-hover-effect(true) {
    color: color("white");
    transform: rotate(3deg);
  }
}

  .c-card2__picture {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    @include transition(opacity);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(color("black"), 0.7);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include card2-hover-effect() {
      opacity: 1;
    }
  }

  .c-card2__body {
    --card2-body-spacing: #{rem(10px)};

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: rem(20px);
    text-align: center;

    > * {
      max-width: rem(200px);
      margin-top: 0;
      margin-bottom: 0;
    }

    > * + * {
      margin-top: var(--card2-body-spacing);
    }

    @include mq($until: "medium") {
      min-height: rem(285px);
    }

    @include mq($from: "medium") {
      min-height: rem(385px);
    }
  }

    .c-card2__icon {
      font-size: rem(80px);
    }

    .c-card2__label {
      @include card2-hover-effect() {
        --label-background: #{color("white")};
        --label-color: #{color("secondary")};
      }
    }

    .c-card2__title {
      @include typo-h4;
      color: color("secondary");

      @include card2-hover-effect() {
        color: currentColor;
      }
    }

      .c-card2__link {
        text-decoration: none;
      }

    .c-card2__button {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      @include card2-hover-effect() {
        --button-border-color: #{color("white")};
        --button-color: #{color("white")};
      }
    }
