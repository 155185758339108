/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Ratio
 * Type: Object
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-ratio {
  --ratio: #{math.div(9, 16)};

  position: relative;
  padding-bottom: calc(var(--ratio) * 100%);
}

  .o-ratio__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
