/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Course view
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-course {
  position: relative;
  z-index: 1;
  display: grid;
  margin-top: calc(var(--header-height) * -1);

  @include mq($until: "large") {
    grid-template-columns: 1fr min(100% - 2 * var(--wrapper-gutter), var(--wrapper-width)) 1fr;
    grid-template-rows: min-content min-content;
  }

  @include mq($until: "medium") {
    padding-bottom: rem(40px);
  }

  @include mq($from: "medium") {
    padding-bottom: rem(60px);
  }

  @include mq($from: "large") {
    grid-template-columns:
      1fr
      min(63.5% - var(--wrapper-gutter), rem(762px))
      rem(60px)
      min(32% - var(--wrapper-gutter), rem(384px))
      1fr;
    grid-template-rows: min-content min-content 1fr;
  }
}

  .c-course__bg {
    position: relative;
    overflow: hidden;
    grid-column: 1 / 6;
    grid-row: 1 / 3;
    background: center /cover no-repeat;
    border-radius: var(--doc-frame-radius);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(color("tertiary"), 0.9);
    }
  }

  .c-course__breadcrumb,
  .c-course__head {
    position: relative;
    z-index: 1;
    color: color("white");
  }

  .c-course__breadcrumb {
    margin-top: calc(var(--header-height) + var(--course-breadcrumb-mt));
    margin-bottom: rem(30px);
    text-align: center;

    @include mq($until: "large") {
      grid-column: 2 / 3;
      grid-row: 1;
    }

    @include mq($until: "medium") {
      --course-breadcrumb-mt: 1rem;
    }

    @include mq($from: "medium", $until: "large") {
      --course-breadcrumb-mt: #{rem(30px)};
    }

    @include mq($from: "large") {
      --course-breadcrumb-mt: #{rem(40px)};

      grid-column: 2 / 5;
      grid-row: 1;
    }
  }

  .c-course__head {
    @include mq($until: "large") {
      grid-column: 2 / 3;
      grid-row: 2;
      margin-bottom: rem(40px);
      text-align: center;
    }

    @include mq($from: "large") {
      display: flex;
      align-items: center;
      grid-column: 2 / 3;
      grid-row: 2;
      margin-bottom: rem(60px);
    }
  }

    .c-course__picture {
      flex: 0 0 auto;
      width: rem(205px);
      margin-right: rem(60px);
      overflow: hidden;
      border: rem(4px) solid color("white");
      border-radius: 50%;

      @include mq($until: "large") {
        display: none;
      }
    }

    .c-course__infos {
      > * {
        margin-top: 0;
        margin-bottom: 0;
      }

      > * + * {
        margin-top: rem(10px);
      }
    }

      .c-course__label {}

      .c-course__title {
        @include typo-h3;
      }

      .c-course__caption {
        @include mq($until: "medium") {
          font-size: rem(14px);
        }

        @include mq($from: "large") {
          font-size: rem(20px);
        }
      }

  .c-course__content {
    @include mq($until: "large") {
      grid-row: 4;
      grid-column: 2 / 3;
    }

    @include mq($from: "large") {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
    }
  }

    .c-course__tabs {
      position: sticky;
      top: 0;
      z-index: 2;
      padding-top: var(--wrapper-gutter);
      padding-bottom: var(--wrapper-gutter);
      margin-bottom: calc(var(--wrapper-gutter) * -1);
      background:
        linear-gradient(
          to bottom,
          var(--doc-frame-background) calc(100% - var(--wrapper-gutter)), rgba(var(--doc-frame-background-rgb), 0) 100%
        );
    }

    .c-course__section {
      position: relative;
      z-index: 1;
      @include mq($until: "medium") {
        margin-top: rem(20px);
      }

      @include mq($from: "medium") {
        margin-top: rem(40px);
      }
    }

      .c-course__subtitle {
        @include typo-h5;
        margin-top: 0;
        margin-bottom: rem(20px);
        color: color("primary");
      }

  .c-course__side {
    @include mq($until: "large") {
      grid-row: 3;
      grid-column: 2 / 3;
      margin-top: rem(30px);
      margin-bottom: rem(30px);
    }

    @include mq($from: "large") {
      grid-column: 4 / 5;
      grid-row: 2 / 4;
    }
  }

    .c-course__details {
      position: sticky;
      top: var(--wrapper-gutter);
    }

      .c-course__button,
      .c-course__meta {
        width: 100%;
      }

      .c-course__contact {
        text-align: center;

        strong {
          display: block;
          color: color("secondary");
        }
      }

/*******************************************************************************
 * Print styles
*******************************************************************************/

@media only print {
  .c-course {
    margin-top: 20pt;
  }

    .c-course__tabs {
      display: none;
    }
}
