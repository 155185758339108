/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Label
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-label {
  --label-background: #{color("secondary")};
  --label-color: #{color("white")};
  --label-border: transparent;

  display: inline-block;
  padding: rem(8px 10px);
  font-size: rem(12px);
  font-weight: $FONT_BOLD;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  color: var(--label-color);
  background: var(--label-background);
  border: rem(1px) solid var(--label-border);
  border-radius: rem(5px);
}

.c-label--rotated {
  box-shadow: rem(0 10px 20px) rgba(color("black"), 0.15);
  transform: rotate(-5.3deg);
}
