/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Nav
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

:root {
  --header-nav-2col-bp: #{mq-px2em(mq-get-breakpoint-width("medium"))};
}

/**
 * [1] Hide elements behind if the page is longer than the viewport.
**/
.c-nav {
  position: fixed;
  top: var(--doc-frame-gap);
  right: var(--doc-frame-gap);
  bottom: var(--doc-frame-gap);
  left: var(--doc-frame-gap);
  padding-top: var(--header-height);
  overflow: hidden;
  color: color("white");
  background: color("black");
  border-radius: var(--doc-frame-radius);
  box-shadow: 0 0 0 calc(var(--doc-frame-gap) * 3) color("white"); // [1]

  &:not([aria-hidden="false"]) {
    display: none;
  }

  @include mq($from: "large") {
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: rem(-90px);
      width: rem(370px);
      height: rem(370px);
      margin-top: rem(370px * -0.5);
      background: url("../images/front/nav-decoration.svg") center no-repeat color("black");
      opacity: 0.1;
    }
  }
}

  .c-nav__scroller {
    height: 100%;
    overflow: auto;
    overscroll-behavior: contain;
  }

    .c-nav__wrapper {
      @include mq($until: "medium") {
        padding-top: rem(30px);
        padding-bottom: rem(30px);
      }

      @include mq($from: "medium", $until: "large") {
        padding-top: rem(60px);
        padding-bottom: rem(60px);
      }

      @include mq($from: "large") {
        padding-top: rem(90px);
        padding-bottom: rem(90px);
      }
    }

      .c-nav__main {
        position: relative;
      }

        .c-nav__items {
          --nav-items-spacing: #{rem(20px)};

          padding: 0;
          margin: 0;
          list-style: none;
        }

        .c-nav__items--root {
          @include mq($until: "medium") {
            --nav-items-spacing: #{rem(50px)};
          }

          @include mq($from: "medium") {
            --nav-items-spacing: #{rem(40px)};

            width: 35.5%;
          }
        }

        .c-nav__items--sub {
          &[aria-hidden="true"] {
            display: none;
          }

          @include mq($until: "medium") {
            --nav-items-spacing: #{rem(30px)};

            margin-top: rem(30px);
          }

          @include mq($from: "medium") {
            --nav-items-spacing: #{rem(50px)};

            position: absolute;
            top: 0;
            left: 42.5%;
          }
        }

        .c-nav__items--sub2 {
          margin-top: rem(20px);
        }

          .c-nav__item {
            &:not(:first-child) {
              margin-top: var(--nav-items-spacing);
            }
          }

          .c-nav__item--root {}

          .c-nav__item--sub {}

          .c-nav__item--sub2 {}

          .c-nav__item--register {
            .c-button {
              width: 100%;
            }

            @include mq($from: "medium") {
              display: none;
            }
          }

            .c-nav__link {
              text-decoration: none;
            }

            .c-nav__link--root {
              @include typo-h3;
              @include transition(opacity);

              .submenu-is-open &:not([aria-expanded="true"]) {
                opacity: 0.5;
              }
            }

            .c-nav__link--sub {
              font-size: rem(14px);
              font-weight: $FONT_BOLD;
              text-transform: uppercase;
            }

            .c-nav__link--sub2 {
              font-size: rem(14px);
              text-transform: uppercase;
            }
