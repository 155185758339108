/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Error view
 * Type: Component
**/

@use "sass:math";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-error {
  --wrapper-width: #{rem(600px)};

  padding-top: calc(var(--header-height) + var(--error-pad));
  padding-bottom: var(--error-pad);
  margin-top: calc(var(--header-height) * -1);
  margin-bottom: var(--doc-frame-gap);
  text-align: center;
  color: color("white");
  background: color("black");
  border-radius: var(--doc-frame-radius);

  @include mq($until: "medium") {
    --error-pad: #{rem(60px)};
  }

  @include mq($from: "medium", $until: "large") {
    --error-pad: #{rem(80px)};
  }

  @include mq($from: "large") {
    --error-pad: #{rem(110px)};
  }
}

  .c-error__inner {
    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    > * + * {
      margin-top: clamp(rem(10px), calc(-1rem + 6vw), rem(40px));
    }
  }

    .c-error__title {
      @include typo-h2;
    }

    .c-error__text {}

    .c-error__button {}
