/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Typography
 * Type: Element
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

a {
  text-decoration: underline;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

address {
  font-style: normal;
}

strong {
  font-weight: $FONT_BOLD;
}

h1 {
  @include typo-h1;
}

h2 {
  @include typo-h2;
}

h3 {
  @include typo-h3;
}

h4 {
  @include typo-h4;
}

h5 {
  @include typo-h5;
}

hr {
  margin: 2em 0;
  color: inherit;
  border-top: 1px dashed currentColor;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}

/*******************************************************************************
 * Print styles
*******************************************************************************/

@media print {
  a {
    text-decoration: none;
    color: #000 !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    display: block;
    break-inside: avoid;
  }

  p {
    orphans: 3;
    widows: 3;
  }
}
