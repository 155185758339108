/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Box-sizing
 * Type: Generic
 * Description:
 *   Define a more sensible default box-sizing
 *   (https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice).
**/

/*******************************************************************************
 * Styles
*******************************************************************************/

html {
  box-sizing: border-box;
}

* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}
