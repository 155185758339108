/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Root
 * Type: Generic
**/

/*******************************************************************************
 * Styles
*******************************************************************************/

:root {}

@-ms-viewport { width: device-width; }
