/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Home overview
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-home-overview {
  color: color("white");
  background: color("black");

  @include mq($until: "large") {
    margin-right: calc(var(--doc-frame-gap) * -1);
    margin-left: calc(var(--doc-frame-gap) * -1);
  }
}

  .c-home-overview__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    > * + * {
      margin-top: var(--home-overview-child-spacing, 0);
    }

    @include mq($until: "medium") {
      padding-top: rem(40px);
      padding-bottom: rem(40px);
    }

    @include mq($from: "medium") {
      padding-top: rem(70px);
      padding-bottom: rem(70px);
    }
  }

    .c-home-overview__label {
      --label-background: #{color("white")};
      --label-color: #{color("secondary")};
    }

    .c-home-overview__title {
      --home-overview-child-spacing: #{rem(10px)};

      max-width: rem(590px);
      @include typo-h3;
      text-align: center;
      color: color("tertiary");
    }

    .c-home-overview__grid {
      width: 100%;

      @include mq($until: "medium") {
        --home-overview-child-spacing: #{rem(20px)};
      }

      @include mq($from: "medium") {
        --home-overview-grid-spacing: 5%;

        display: flex;
        flex-wrap: wrap;
      }

      @include mq($from: "medium", $until: "large") {
        --home-overview-child-spacing: #{rem(40px)};
      }

      @include mq($from: "large") {
        --home-overview-child-spacing: #{rem(60px)};
      }
    }

      .c-home-overview__column {
        > * {
          margin-top: 0;
          margin-bottom: 0;
        }

        > * + * {
          margin-top: rem(20px);
        }

        @include mq($until: "medium") {
          & + & {
            padding-top: rem(20px);
            margin-top: rem(20px);
            border-top: rem(1px) solid rgba(color("white"), 0.2);
          }
        }

        @include mq($from: "medium") {
          padding-right: 5%;
          padding-left: 5%;
          border-left: rem(1px) solid rgba(color("white"), 0.2);
        }

        @include mq($from: "medium", $until: "large") {
          flex: 0 0 50%;

          &:nth-child(odd) {
            padding-left: 0;
            border-left: 0;
          }

          &:nth-child(even) {
            padding-right: 0;
          }

          &:nth-child(n+3) {
            margin-top: rem(30px);
          }
        }

        @include mq($from: "large") {
          flex: 0 0 27.5%;

          &:nth-child(4n-3) {
            flex-basis: 22.5%;
            padding-left: 0;
            border-left: 0;
          }

          &:nth-child(4n) {
            flex-basis: 22.5%;
            padding-right: 0;
          }

          &:nth-child(n+5) {
            margin-top: rem(30px);
          }
        }
      }

        .c-home-overview__icon {
          font-size: rem(80px);
        }

        .c-home-overview__subtitle {
          @include typo-headline;
        }

        .c-home-overview__list {
          padding: 0;
          margin-bottom: 0;
          list-style: none;
        }

          .c-home-overview__item {
            & + & {
              margin-top: rem(10px);
            }
          }

            .c-home-overview__link {
              --button-text-align: left;
            }
