/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form
 * Type: Element
**/

/*******************************************************************************
 * Styles
*******************************************************************************/

button {
  font: inherit;
  line-height: inherit;
  cursor: pointer;
  color: inherit;
}
