/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Contact view
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-contact {}

  .c-contact__wrapper {

    @include mq($until: "large") {
      --wrapper-width: #{rem(600px)};
    }

    @include mq($from: "large") {
      --wrapper-width: #{rem(1100px)};

      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

    .c-contact__form {
      @include mq($from: "large") {
        max-width: rem(590px);
      }
    }

    .c-contact__details {
      @include mq($until: "large") {
        margin-top: rem(40px);
      }

      @include mq($from: "large") {
        position: sticky;
        top: rem(20px);
        flex: 0 0 auto;
        width: 35%;
        max-width: rem(385px);
        margin-left: 11%;
      }
    }

      .c-contact__details-subtitle {
        @include typo-headline;
        color: color("secondary");
      }
