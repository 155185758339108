/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Field
 * Type: Component
 * Description: A group of elements which purpose is to get user's input.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

/**
 * [1] Reset fieldset styles, read : https://thatemil.com/blog/2015/01/03/reset-your-fieldset/
 */
.c-form-field {
  min-width: 0; /* [1] */
  padding: 0.01em 0 0; /* [1] */
  margin: 0; /* [1] */
  border: 0; /* [1] */

  body:not(:-moz-handler-blocked) & {
    display: table-cell; /* [1] */
  }
}

  /**
   * [1] Reset legend styles, read : https://thatemil.com/blog/2015/01/03/reset-your-fieldset/
   */
  .c-form-field__label {
    display: table; /* [1] */
    padding: 0; /* [1] */
    @include transition(color);
  }

  .c-form-field__hint {}

  .c-form-field__feedback {
    margin-bottom: rem(6px);
  }

  .c-form-field__control {
    display: flex;

    &:not(:first-child) {
      margin-top: rem(4px);
    }

    > * {
      flex: var(--flex, 1 1 auto);

      &:not(:first-child) {
        margin-left: rem(8px);
      }
    }
  }

/**
 * Focus styles
 ******************************************************************************/

.c-form-field:focus-within {
  .c-form-field__label {
    color: color("secondary");
  }
}

/**
 * Option list
*******************************************************************************/

.c-form-field--optionset {
  .c-form-field__control {
    flex-direction: column;

    &:not(:first-child) {
      margin-top: rem(8px);
    }

    > *:not([type="hidden"]) + *:not(:first-child) {
      margin-top: rem(8px);
    }

    > *:not(:first-child) {
      margin-left: 0;
    }
  }
}
