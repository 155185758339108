/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Grid
 * Type: Object
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-grid {
  --grid-x-spacing: #{rem(24px)};
  --grid-y-spacing: #{rem(24px)};

  display: flow-root;
}

  .o-grid__items {
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--grid-y-spacing) * -0.5);
    margin-right: calc(var(--grid-x-spacing) * -0.5);
    margin-bottom: calc(var(--grid-y-spacing) * -0.5);
    margin-left: calc(var(--grid-x-spacing) * -0.5);

    .o-grid--center & {
      justify-content: center;
    }
  }

    .o-grid__item {
      flex: 0 0 auto;
      width: 100%;
      padding-top: calc(var(--grid-y-spacing) * 0.5);
      padding-right: calc(var(--grid-x-spacing) * 0.5);
      padding-bottom: calc(var(--grid-y-spacing) * 0.5);
      padding-left: calc(var(--grid-x-spacing) * 0.5);
    }

    .o-grid__item--stretch > * {
      height: 100%;
    }

/*******************************************************************************
 * Variants
*******************************************************************************/

.o-grid--figures {
  @include mq($until: "medium") {
    .o-grid__item:not(:first-child) {
      display: none;
    }
  }
}

.o-grid--news {
  @include mq($until: "medium") {
    --grid-y-spacing: #{rem(40px)};
  }

  @include mq($from: "medium") {
    --grid-y-spacing: #{rem(50px)};
  }
}

.o-grid--numbers {
  @include mq($from: "medium") {
    --grid-y-spacing: #{rem(60px)};
  }
}

/*******************************************************************************
 * Print styles
*******************************************************************************/

@media only print {
  .o-grid__item {
    break-inside: avoid;
  }
}
