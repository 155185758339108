/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Header
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

:root {
  @include mq($until: "medium") {
    --header-height: #{rem(72px)};
  }

  @include mq($from: "medium", $until: "large") {
    --header-height: #{rem(84px)};
  }

  @include mq($from: "large") {
    --header-height: #{rem(105px)};
  }
}

.c-header {
  position: relative;
  z-index: $global-z-header;
  color: color("white");
  border-top-left-radius: var(--doc-frame-radius);
  border-top-right-radius: var(--doc-frame-radius);

  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: rem(1px);
    background: rgba(color("white"), 0.2);
  }

  .header-theme-glass & {
    background: rgba(color("white"), 0.5);
  }

  .header-theme-glass:not(.nav-is-open) & {
    color: color("foreground");
  }
}

  .c-header__main {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    height: var(--header-height);
  }

  .c-header__burger {
    @include mq($until: "medium") {
      margin-right: rem(16px);
    }

    @include mq($from: "medium", $until: "large") {
      margin-right: rem(30px);
    }
  }

  .c-header__home {
    @include mq($until: "medium") {
      margin-right: rem(20px);
      }

    @include mq($from: "large") {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

    .c-header__logo {
      @include mq($until: "medium") {
        --logo-size: #{rem(110px)};
      }

      @include mq($from: "medium", $until: "large") {
        --logo-size: #{rem(120px)};
      }
    }

  .c-header__supnav {
    margin-left: auto;
  }

/*******************************************************************************
 * Print styles
*******************************************************************************/

@media only print {
  .c-header {
    margin-bottom: 10pt;
  }

    .c-header__main {
      justify-content: center;
      height: auto;
    }

      .c-header__burger,
      .c-header__supnav {
        display: none;
      }

      .c-header__home {
        position: static;
        transform: none;
      }

        .c-header__logo {
          --logo-size: 150pt;
        }
}
