/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Quote
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";

$quote-mark: "<svg viewBox=\"0 0 95 71\" xmlns=\"http://www.w3.org/2000/svg\">\
  <path d=\"M23.19 70.93c-4.16 0-7.73-.76-10.72-2.27-3-1.5-5.32-3.7-6.98-6.54-1.83-2.35-3.25-5.46-4.24-9.32A54.9 54.9 0 0 1 0 41.22a44.8 44.8 0 0 1 6.23-23.17C10.56 10.83 17.2 5.12 26.18.93l2.25 4.53a34.85 34.85 0 0 0-12.47 9.32c-3.66 4.2-5.82 8.47-6.48 12.84-.84 3.7-.84 7.22 0 10.57 3.65-4.02 8.47-6.04 14.46-6.04 5.81 0 10.55 1.76 14.21 5.29 3.66 3.36 5.49 8.06 5.49 14.1 0 5.88-2 10.58-5.99 14.1-3.82 3.53-8.64 5.29-14.46 5.29Zm51.36 0c-4.15 0-7.73-.76-10.72-2.27a16.51 16.51 0 0 1-6.98-6.54c-1.83-2.35-3.24-5.46-4.24-9.32a54.9 54.9 0 0 1-1.25-11.58 44.8 44.8 0 0 1 6.24-23.17C61.92 10.83 68.57 5.12 77.55.93l2.24 4.53a34.85 34.85 0 0 0-12.47 9.32c-3.65 4.2-5.82 8.47-6.48 12.84-.83 3.7-.83 7.22 0 10.57 3.66-4.02 8.48-6.04 14.46-6.04 5.82 0 10.56 1.76 14.21 5.29C93.17 40.8 95 45.5 95 51.54c0 5.88-2 10.58-5.98 14.1-3.83 3.53-8.65 5.29-14.47 5.29Z\" fill=\"%color%\" />\
</svg>";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-quote {
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: color("background-alt");
  }

  @include mq($until: "medium") {
    padding-top: rem(20px);
    padding-bottom: rem(20px);
  }

  @include mq($from: "medium") {
    padding-top: rem(50px);

    &::before {
      bottom: rem(140px);
    }
  }
}

  .c-quote__wrapper {
    --wrapper-width: #{rem(1100px)};
  }

    .c-quote__inner {
      @include mq($from: "medium") {
        display: flex;
      }
    }

      .c-quote__figure {
        @include mq($from: "medium") {
          flex: 0 0 auto;
          width: 44%;
          margin-right: 11.5%;
        }
      }

      .c-quote__main {
        margin-top: rem(30px);

        @include mq($from: "medium") {
          flex: 1 1 auto;
        }
      }

        .c-quote__head {}

          .c-quote__label {
            margin-top: rem(10px);
          }

          .c-quote__title {
            @include typo-h3;
            color: color("secondary");
          }

        .c-quote__body {
          position: relative;
          margin-top: calc(var(--quote-body-mt) + var(--quote-mark-offset));
          text-align: center;
          color: color("white");
          background: color("tertiary");

          &::before {
            content: "";
            position: absolute;
            top: calc(var(--quote-mark-offset) * -1);
            left: rem(20px);
            width: var(--quote-mark-size);
            height: calc(var(--quote-mark-size) * #{math.div(72px, 95px)});
            background-image: icon($quote-mark, color("foreground"));
            background-size: 100%;
          }

          @include mq($until: "medium") {
            --quote-body-mt: #{rem(10px)};
          }

          @include mq($until: "large") {
            --quote-mark-size: #{rem(95px)};
            --quote-mark-offset: #{rem(55px)};

            padding: rem(40px 15px);
          }

          @include mq($from: "medium") {
            --quote-body-mt: #{rem(30px)};

            margin-left: -42%;

            &::before {
              content: "";
              left: rem(70px);
            }
          }

          @include mq($from: "large") {
            --quote-mark-size: #{rem(160px)};
            --quote-mark-offset: #{rem(90px)};

            padding: rem(60px 15px);
          }
        }

          .c-quote__text,
          .c-quote__author {
            max-width: rem(486px);
            margin-right: auto;
            margin-left: auto;
          }

          .c-quote__text {
            @include mq($from: "large") {
              font-size: rem(20px);
            }
          }

          .c-quote__author {
            @include typo-h5;
            margin-top: rem(20px);
          }

/*******************************************************************************
 * Print styles
*******************************************************************************/

@media only print {
  .c-quote {
    break-inside: avoid;
  }

    .c-quote__body {
      margin-top: calc(var(--quote-body-mt));
      background: color("white") !important;
      border: 1pt solid;
      border-radius: rem(20px);
    }
}
