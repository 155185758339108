/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Fonts
 * Type: Generic
**/

/*******************************************************************************
 * Styles
*******************************************************************************/

@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: $FONT_REGULAR;
  src: url("#{$font-folder}montserrat-regular.woff2")  format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-style: italic;
  font-weight: $FONT_REGULAR;
  src: url("#{$font-folder}montserrat-regular-italic.woff2")  format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: $FONT_BOLD;
  src: url("#{$font-folder}montserrat-bold.woff2")  format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-style: italic;
  font-weight: $FONT_BOLD;
  src: url("#{$font-folder}montserrat-bold-italic.woff2")  format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: $FONT_BOLD;
  src: url("#{$font-folder}playfair-display-bold.woff2")  format("woff2");
}
