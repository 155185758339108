/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Main template
 * Type: Object
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-main {}

  .o-main__head {
    position: relative;
    z-index: var(--doc-frame-rule-z);
  }

  .o-main__body {
    @include mq($until: "medium") {
      padding-bottom: rem(40px);
    }

    @include mq($from: "medium") {
      padding-bottom: rem(60px);
    }
  }

    .o-main__toolbar {
      padding-top: rem(30px);
      border-bottom: rem(1px) solid rgba(color("secondary"), 0.2);

      @include mq($until: "medium") {
        padding-bottom: rem(10px);
      }

      @include mq($from: "medium") {
        padding-bottom: rem(20px);
      }
    }

    .o-main__content {
      @include mq($until: "medium") {
        padding-top: rem(30px);
      }

      @include mq($from: "medium") {
        padding-top: rem(60px);
      }
    }

      .o-main__pagination {
        max-width: rem(600px);
        margin-right: auto;
        margin-left: auto;

        @include mq($until: "medium") {
          margin-top: rem(40px);
        }

        @include mq($from: "medium") {
          margin-top: rem(60px);
        }
      }
