/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: A11Y skip links
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-a11y-skip {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $global-z-header + 1;
  padding-top: rem(40px);
  padding-bottom: rem(40px);
  background: color("secondary");
  transform: translateY(-200%);

  &:focus-within {
    transform: translateY(0);
  }
}

  .c-a11y-skip__wrapper {}

    .c-a11y-skip__items {
      display: flex;
      flex-wrap: wrap;
      margin: rem(-20px);
    }

      .c-a11y-skip__item {
        padding: rem(20px);
      }
