/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Blocks
 * Type: Object
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-blocks {
  @include mq($until: "medium") {
    --blocks-spacing: #{rem(40px)};
  }

  @include mq($from: "medium", $until: "large") {
    --blocks-spacing: #{rem(60px)};
  }

  @include mq($from: "large") {
    --blocks-spacing: #{rem(80px)};
  }
}

  .o-blocks__item {
    display: flow-root;

    & + & {
      margin-top: var(--blocks-spacing);
    }
  }

    .o-blocks__wrapper {}

      .o-blocks__inner {}

/*******************************************************************************
 * Variants
*******************************************************************************/

.o-blocks--course {
  --wrapper-gutter: 0;

  @include mq($from: "medium") {
    --blocks-spacing: #{rem(40px)};
  }
}

.o-blocks__item--title,
.o-blocks__item--text,
.o-blocks__item--dark-text,
.o-blocks__item--framed-text,
.o-blocks__item--button {
  .o-blocks__wrapper {
    --wrapper-width: #{rem(792px)};
  }
}

.o-blocks__item--dark-text,
.o-blocks__item--framed-text {
  padding-top: rem(30px);
  padding-bottom: rem(30px);
  border-radius: rem(20px);

  @include mq($until: "medium") {
    --wrapper-gutter: #{rem(20px)};
  }

  @include mq($from: "medium") {
    --wrapper-gutter: #{rem(40px)};
  }
}

.o-blocks__item--dark-text {
  color: color("white");
  background: color("black");
}

.o-blocks__item--framed-text {
  border: rem(1px) solid color("secondary");
}

.o-blocks__item--media-text,
.o-blocks__item--figure {
  .o-blocks__wrapper {
    --wrapper-width: #{rem(1000px)};
  }
}

.o-blocks__item--quote {
  .o-blocks__wrapper {
    --wrapper-width: #{rem(1100px)};
  }
}

.o-blocks__item--title,
.o-blocks__item--button {
  text-align: center;
}

/*******************************************************************************
 * Print styles
*******************************************************************************/

@media only print {
  .o-blocks__item--dark-text {
    border: rem(1px) solid
  }
}
