/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Hero
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-hero {
  position: relative;
  z-index: var(--doc-frame-rule-z);
  padding-top: var(--header-height);
  margin-top: calc(var(--header-height) * -1);
  color: color("white");
  background: color("primary");
  border-radius: var(--doc-frame-radius);
}

  .c-hero__top {
    text-align: center;

    @include mq($until: "medium") {
      padding-top: rem(20px);
    }

    @include mq($from: "medium") {
      padding-top: rem(40px);
    }
  }

  .c-hero__main {
    @include mq($until: "medium") {
      max-width: calc(var(--wrapper-width) + 2 * var(--wrapper-gutter));
      padding-top: rem(20px);
      padding-right: var(--wrapper-gutter);
      padding-left: var(--wrapper-gutter);
    }

    @include mq($from: "medium") {
      --hero-margin-percent: 0.04;

      display: grid;
      padding-top: rem(40px);
      padding-bottom: rem(40px);
      grid-template-columns:
        minmax(var(--wrapper-gutter), 1fr)
        min(calc(0.57 * (100% - 2 * var(--wrapper-gutter))), rem(585px))
        clamp(rem(26px), calc(var(--hero-margin-percent) * (100% - 2 * var(--wrapper-gutter))), rem(95px))
        minmax(auto, calc(var(--wrapper-width) - rem(585px) - rem(95px)))
        minmax(var(--wrapper-gutter), 1fr);
    }

    @include mq($from: "large") {
      --hero-margin-percent: 0.08;
    }
  }

    .c-hero__content {
      align-self: center;

      > * {
        margin-top: 0;
        margin-bottom: 0;
      }

      > * + * {
        @include mq($until: "medium") {
          margin-top: rem(10px);
        }

        @include mq($from: "medium") {
          margin-top: rem(30px);
        }
      }

      @include mq($from: "medium") {
        grid-column: 2 / 3;
        flex: 0 0 auto;
      }
    }

      .c-hero__title {
        span {
          color: color("secondary");
        }
      }

    .c-hero__picture {
      width: fit-content;
      margin-right: calc((var(--wrapper-gutter) + var(--doc-frame-gap)) * -1);
      overflow: hidden;
      border-top-left-radius: rem(20px);

      @include mq($until: "medium") {
        max-width: 84%;
        margin-top: rem(20px);
        margin-left: auto;
      }

      @include mq($from: "medium") {
        grid-column: 4 / 6;
        margin-bottom: rem(-40px);
        border-top-right-radius: rem(20px);
      }
    }

/*******************************************************************************
 * Print styles
*******************************************************************************/

@media only print {
  .c-hero__picture {
    display: none;
  }
}
