/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Card
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-card {
  position: relative;
}

  .c-card__head {
    position: relative;
  }

    .c-card__picture {
      overflow: hidden;
      border-radius: rem(20px);
    }

    .c-card__label {
      --label-background: #{color("white")};
      --label-color: #{color("secondary")};

      position: absolute;
      top: rem(20px);
      left: rem(20px);
      max-width: calc(100% - #{rem(40px)});
    }

  .c-card__body {
    --card-body-spacing: #{rem(10px)};

    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    > * + * {
      margin-top: var(--card-body-spacing);
    }

    @include mq($until: "medium") {
      padding-top: rem(20px);
    }

    @include mq($from: "medium") {
      padding-top: rem(30px);
    }
  }

    .c-card__meta {
      color: color("primary");
    }

    .c-card__suptitle {
      font-size: rem(20px);
      color: color("primary");
    }

    .c-card__title {
      color: color("secondary");

      &:not(.c-card__title--big) {
        @include typo-h5;
      }

      &.c-card__title--big {
        @include typo-h2;
      }
    }

      .c-card__link {
        text-decoration: none;
      }

    .c-card__caption {}

    .c-card__button {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      @include mq($until: "medium") {
        --card-body-spacing: #{rem(20px)};
      }

      @include mq($from: "medium") {
        --card-body-spacing: #{rem(30px)};
      }
    }

/*******************************************************************************
 * Print styles
*******************************************************************************/

@media only print {
  .c-card__label {
    background: color("white") !important;
  }
}
