/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Supnav
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-supnav {}

  .c-supnav__items {
    display: flex;
    align-items: center;
    padding: 0;
    margin: rem(-10px);
    list-style: none;
  }

    .c-supnav__item {
      padding: rem(10px);
    }

    .c-supnav__item--register {
      @include mq($until: "medium") {
        display: none;
      }

      .header-theme-glass:not(.nav-is-open) & {
        .c-button:hover,
        .c-button.focus-visible {
          --button-bg: #{color("primary")};
        }
      }
    }

      .c-supnav__link {
        font-size: rem(20px);
      }
