/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Home section
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-home-section {}

  .c-home-section__inner {
    display: flex;
    flex-direction: column;

    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    > * + * {
      margin-top: var(--home-section-child-spacing, 0);
    }
  }

  .c-home-section__title {
    max-width: rem(785px);
    @include typo-h3;
    color: color("primary");
  }

  .c-home-section__desc {
    --home-section-child-spacing: #{rem(10px)};

    max-width: rem(585px);
    margin-left: 0;
    @include typo-medium;
  }

  .c-home-section__content {
    --home-section-child-spacing: #{rem(40px)};
  }
