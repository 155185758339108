/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Coordblock
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-coordblock {
  --coordblock-pad: #{rem(20px 30px)};

  overflow: hidden;
  background: color("background");
  border-radius: rem(10px);
  box-shadow: rem(0 6px 20px) rgba(0, 0, 0, 0.25);
}

  .c-coordblock__head {
    display: flex;
    align-items: center;
    padding: var(--coordblock-pad);
    color: color("white");
    background: color("secondary");
  }

    .c-coordblock__picture {
      flex: 0 0 auto;
      width: clamp(rem(50px), 18.5%, rem(60px));
      margin-right: rem(24px);
      overflow: hidden;
      border: rem(2px) solid color("white");
      border-radius: 50%;
    }

    .c-coordblock__title {
      flex: 1 1 auto;
      margin-top: 0;
      margin-bottom: 0;
      @include typo-h5;
    }

  .c-coordblock__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--coordblock-pad);

    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    > * + * {
      margin-top: var(--coordblock-data-spacing, #{rem(20px)});
    }
  }

    .c-coordblock__section {
      display: flex;
      flex-direction: column;

      > * {
        margin-top: 0;
        margin-bottom: 0;
      }

      > * + * {
        margin-top: var(--coordblock-data-spacing, #{rem(10px)});
      }
    }

    .c-coordblock__section--full {
      align-items: flex-start;
      width: 100%;
    }

/*******************************************************************************
 * Print styles
*******************************************************************************/

@media only print {
  .c-coordblock {
    border: 1pt solid;
    break-inside: avoid;
  }

    .c-coordblock__head {
      border-bottom: 1pt solid;
    }
}
