/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Pagination
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

  .c-pagination__prev,
  .c-pagination__next {
    @include mq($until: "medium") {
      --button-text-display: none;
      --button-padding: var(--button-medium-icon-only-padding);
    }

    &.is-disabled {
      opacity: 0;
    }
  }

  .c-pagination__items {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
  }

    .c-pagination__item {}

    .c-pagination__item--current {
      color: color("primary");

      @media only print {
        font-weight: $FONT_BOLD;
      }
    }

      .c-pagination__link,
      .c-pagination__text {
        display: block;
        padding: 0.75ch;
      }

      .c-pagination__link {
        text-decoration: none;

        &:hover,
        &.focus-visible {
          text-decoration: underline;
        }
      }

      .c-pagination__text {}
