/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Richtext
 * Type: Scope
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

$richtext-font-size: $global-font-size;
$richtext-line-height: $global-line-height;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.s-richtext {
  @include typo-normal;
  width: 100%;
  max-width: var(--richtext-mxwidth, 69ch);
  margin-right: auto;
  margin-left: auto;

  > * {
    margin: 0;
  }

  > * + * {
    margin-top: var(--richtext-vertical-spacing);

    @include mq($until: "medium") {
      --richtext-vertical-spacing: #{rem(20px)};
    }

    @include mq($from: "medium") {
      --richtext-vertical-spacing: #{rem(30px)};
    }
  }

  a:not(.c-button) {
    font-weight: $FONT_BOLD;
    color: color("secondary");
  }

  h2 {
    color: color("primary");
  }

  h3 {
    color: color("secondary");
  }

  /**
   * List
  ********************/
  ul,
  ol {
    --richtext-li-vertical-spacing: #{rem(10px)};

    display: flow-root;
    max-width: em(588px, $richtext-font-size);
    padding-left: em(30px, $richtext-font-size);
    margin-right: auto;
    margin-left: auto;
    text-align: left;
  }

  ol {
    counter-reset: item;

    li {
      counter-increment: item;
    }

    ::marker {
      content: counter(item, decimal-leading-zero) ". ";
      font-weight: $FONT_BOLD;
    }
  }

  // Set explicite margin for childs because TipTap treat content of ListItem
  // as blocks: https://github.com/ueberdosis/tiptap/issues/118.
  li {
    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    > * + * {
      margin-top: var(--richtext-li-vertical-spacing);
    }
  }

  li + li {
    margin-top: var(--richtext-li-vertical-spacing);
  }

  ::marker {
    color: color("primary");
  }
}

.s-richtext--dark {
  h2,
  h3,
  a:not(.c-button) {
    color: currentColor;
  }

  // Distinc rule for browsers who don't support ::marker selector
  ::marker {
    color: currentColor;
  }
}

/*******************************************************************************
 * Print styles
*******************************************************************************/

@media print {
  .s-richtext {
    a[href]::after {
      content: " [" attr(href) "]";
      font-style: italic;
      opacity: 0.5;
    }

    abbr[title]::after {
      content: " [" attr(title) "]";
      font-style: italic;
      opacity: 0.5;
    }

    ::marker {
      color: currentColor;
    }
  }
}
