/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Footer
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-footer {
  position: relative;
  z-index: $global-z-footer;
}

  .c-footer__pre {
    margin-right: calc(var(--doc-frame-gap) * -1);
    margin-bottom: var(--footer-pre-mb);
    margin-left: calc(var(--doc-frame-gap) * -1);

    @include mq($until: "large") {
      --footer-pre-mb: #{rem(40px)};
    }

    @include mq($from: "large") {
      --footer-pre-mb: #{rem(60px)};
    }
  }

  .c-footer__main {
    margin-right: calc(var(--doc-frame-gap) * -1);
    margin-left: calc(var(--doc-frame-gap) * -1);
    color: color("white");
    background: color("secondary");

    @include mq($from: "large") {
      padding-top: rem(40px);
    }
  }

/**
 * Sitemap
*******************************************************************************/

.c-footer__sitemap-container {
  padding-top: rem(30px);
  padding-bottom: rem(30px);

  @include mq($until: "large") {
    text-align: center;
  }

  @include mq($from: "large") {
    border-top: rem(1px) solid rgba(color("white"), 0.4);
  }
}

  .c-footer__sitemap {
    @include mq($from: "large") {
      display: flex;
    }
  }

    .c-footer__sitemap-section {
      position: relative;

      & + &::after {
        content: "";
        position: absolute;
        background: rgba(color("white"), 0.2);
      }

      @include mq($until: "large") {
        & + & {
          padding-top: rem(20px);
          margin-top: rem(20px);

          &::after {
            top: 0;
            left: 50%;
            width: rem(180px);
            height: rem(1px);
            margin-left: rem(-90px);
          }
        }
      }

      @include mq($from: "large") {
        display: flex;
        align-items: center;

        & + & {
          padding-left: rem(60px);
          margin-left: rem(60px);

          &::after {
            top: 0;
            bottom: 0;
            left: 0;
            width: rem(1px);
          }
        }
      }
    }

      .c-footer__logo {
        margin-right: auto;
        margin-left: auto;

        @include mq($until: "medium") {
          --logo-size: #{rem(150px)};
        }

        @include mq($from: "medium", $until: "large") {
          --logo-size: #{rem(170px)};
        }

        @include mq($from: "large") {
          --logo-size: #{rem(250px)};
        }
      }

      .c-footer__menu {
        padding: 0;
        margin: 0;
        list-style: none;
      }

        .c-footer__menu-item {
          & + & {
            margin-top: rem(20px);
          }
        }

          .c-footer__menu-link {
            font-size: rem(14px);
            font-weight: $FONT_BOLD;
            text-decoration: none;
            text-transform: uppercase;
            letter-spacing: 0.05em;

            &:hover,
            &:focus {
              text-decoration: underline;
            }
          }

      .c-footer__help {
        max-width: rem(350px);
        margin-right: auto;
        margin-left: auto;
      }

        .c-footer__help-title {
          @include typo-h4;
          margin-top: 0;
          margin-bottom: 0;
        }

        .c-footer__help-desc {
          margin-top: rem(20px);
          margin-bottom: rem(20px);
        }

        .c-footer__help-button {}

/**
 * Logotype
*******************************************************************************/

.c-footer__logotype-container {
  padding-top: var(--footer-logotype-pad);
  padding-bottom: var(--footer-logotype-pad);
  border-top: rem(1px) solid rgba(color("white"), 0.4);
  border-bottom: rem(1px) solid rgba(color("white"), 0.4);

  @include mq($until: "large") {
    --footer-logotype-pad: #{rem(30px)};
  }

  @include mq($from: "large") {
    --footer-logotype-pad: #{rem(40px)};
  }
}

  .c-footer__logotype {
    display: block;
    width: min(100%, rem(860px));
    fill: currentcolor;
  }

/**
 * Foot
*******************************************************************************/

.c-footer__foot {
  padding-top: var(--footer-foot-pad);
  padding-bottom: var(--footer-foot-pad);
  font-size: rem(14px);
  text-align: center;

  @include mq($until: "medium") {
    --footer-foot-pad: #{rem(10px)};
  }

  @include mq($from: "medium") {
    --footer-foot-pad: #{rem(20px)};
  }
}

  .c-footer__foot-link {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

/**
 * Gallery
*******************************************************************************/

.c-footer__gallery {
  display: flex;
  margin-right: calc(var(--doc-frame-gap) * -1);
  margin-bottom: calc(var(--doc-frame-gap) * -1);
  margin-left: calc(var(--doc-frame-gap) * -1);
}

  .c-footer__picture {
    flex: 1 1 0;

    img {
      width: 100%;
    }

    @include mq($until: "medium") {
      &:nth-child(n+4) {
        display: none;
      }
    }
  }

/*******************************************************************************
 * Print styles
*******************************************************************************/

@media only print {
  .c-footer {
    display: none;
  }
}
