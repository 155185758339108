/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Footer
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-pre-footer {
  padding-top: var(--pre-footer-main-offset);
  overflow: hidden;
  text-align: center;
  color: color("white");

  @include mq($until: "large") {
    --pre-footer-main-offset: #{rem(46px)};
  }

  @include mq($from: "large") {
    --pre-footer-main-offset: #{rem(83px)};
  }
}

  .c-pre-footer__inner {
    padding-bottom: var(--pre-footer-pb);
    background: color("foreground");

    @include mq($until: "medium") {
      --pre-footer-pb: #{rem(40px)};
    }

    @include mq($from: "medium", $until: "large") {
      --pre-footer-pb: #{rem(70px)};
    }

    @include mq($from: "large") {
      --pre-footer-pb: #{rem(80px)};
    }
  }

    .c-pre-footer__wrapper {
      position: relative;
    }

      .c-pre-footer__picture {
        display: inline-block;
        padding: 1rem;
        border: rem(1px) solid color("orange");

        &,
        img {
          border-radius: var(--pre-footer-picture-radius);
        }
      }

      .c-pre-footer__picture--main {
        --pre-footer-picture-radius: 50%;

        position: relative;
        width: clamp(rem(280px), 40%, rem(485px));
        margin-top: calc(var(--pre-footer-main-offset) * -1);

        &::after {
          content: "";
          position: absolute;
          top: 1rem;
          bottom: rem(-40px);
          left: calc(50% - #{rem(0.5px)});
          width: rem(1px);
          background: color("white");
        }
      }

      .c-pre-footer__picture--secondary {
        position: absolute;
        top: 10%;
        width: clamp(rem(195px), 25%, rem(315px));

        @include mq($until: "medium") {
          display: none;
        }

        @include mq($from: "medium", $until: "large") {
          --pre-footer-picture-radius: #{rem(12px)};
        }

        @include mq($from: "large") {
          --pre-footer-picture-radius: #{rem(20px)};
        }

        &.c-pre-footer__picture--left {
          left: 0;
          transform: rotate(-15deg);
        }

        &.c-pre-footer__picture--right {
          right: 0;
          transform: rotate(15deg);
        }
      }

      .c-pre-footer__text {
        max-width: rem(385px);
        margin-top: rem(70px);
        margin-right: auto;
        margin-left: auto;
        text-align: center;
      }

        .c-pre-footer__title {
          @include typo-h3;
        }

        .c-pre-footer__button {}
